import React from 'react';
import { Section, Inner, contentStyles } from 'components';

function Text({ textNode }) {
  return (
    <Section tw="sm:py-0">
      <Inner>
        <div
          tw="max-w-5xl mx-auto"
          css={contentStyles}
          dangerouslySetInnerHTML={{ __html: textNode.childMarkdownRemark.html }}
        />
      </Inner>
    </Section>
  );
}

export default Text;
