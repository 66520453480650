import React from 'react';
import tw, { css } from 'twin.macro';
import { Section, Inner, SectionTitle } from 'components';

const gridStyles = css`
  ${tw`grid grid-cols-2 md:grid-cols-3 mt-10 border-l border-t border-white-50`}
  li {
    ${tw`p-5 sm:p-8 m-0! flex! flex-col lg:grid border-b border-r border-white-50`}
  }
`;

const gridCols4 = css`
  ${tw`md:grid-cols-4`}
`;

const gridCols2 = css`
  ${tw`md:grid-cols-2`}
`;

const gridCols3 = css`
  ${tw`md:grid-cols-3`}
`;

const H3 = tw.h3`mb-10 leading-tight text-l sm:text-2xl md:h-20 xl:(h-14 text-3xl)`;
const towardsStyles = tw`text-xl md:text-4xl mb-0 text-green`;

const columnStyles = (cols) => {
  if (cols === 4) return gridCols4;
  if (cols === 2) return gridCols2;
  return gridCols3;
};

function FactBoxes({ heading, facts, bigFont15Degrees }) {
  return (
    <Section>
      <Inner>
        {heading && <SectionTitle>{heading}</SectionTitle>}
        <ul css={[gridStyles, columnStyles(facts.length)]}>
          {facts.map(({ heading, bodyText }, i) => (
            <li key={i}>
              <H3 css={bigFont15Degrees && towardsStyles}>{heading}</H3>
              <p tw="opacity-90">{bodyText}</p>
            </li>
          ))}
        </ul>
      </Inner>
    </Section>
  );
}

export default FactBoxes;
