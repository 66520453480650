import React, { useEffect, useRef } from 'react';
import tw, { css } from 'twin.macro';
import GlueFrame from 'glueframe';
import { Section, Inner, contentStyles } from 'components';

const reverseOrderStyles = css`
  @media only screen and (min-width: 769px) {
    grid-auto-flow: column;
    > div:first-of-type {
      grid-column-end: -1;
    }
  }
`;

const gridStyles = tw`flex flex-col-reverse sm:(grid grid-cols-2 grid-rows-1 gap-12)`;
const iframeContainerStyles = css`
  padding-bottom: 56.25%;
  ${tw`relative h-0 w-full`}
`;
const iframeStyles = tw`w-full h-full absolute top-0 left-0`;

const sectionTitleStyles = tw`font-semibold m-0 leading-tight text-xl sm:text-4xl`;

function TextAndVideo({ heading, bodyTextNode, videoEmbedUrl, reverseOrder, isHero, ...rest }) {
  const ref = useRef();

  useEffect(() => {
    const videoTitle = heading;
    if (typeof window !== 'undefined') {
      const Iframe = new GlueFrame(ref.current, 'Player');
      Iframe.bind('player:video:playing', () => {
        window.dataLayer = window.dataLayer || [];

        const firstPlay = !window.dataLayer.some(({ event }) => event === 'video_play');
        const sameVideo = window.dataLayer.some((item) => item.video_title === videoTitle);

        if (firstPlay || !sameVideo) {
          window.dataLayer.push({
            event: 'video_play',
            video_title: videoTitle,
          });
        }

        /* if (window.gtag) {
          window.gtag('event', 'video_play', {
            event_category: 'video_play',
            event_label: videoTitle,
          });
        } */
      });
    }
  }, [heading]);

  const Title = isHero ? 'h1' : 'h2';

  return (
    <Section {...rest}>
      <Inner css={[gridStyles, reverseOrder && reverseOrderStyles]}>
        <div>
          <Title css={sectionTitleStyles}>{heading}</Title>
          <div
            tw="mt-4"
            css={contentStyles}
            dangerouslySetInnerHTML={{ __html: bodyTextNode.childMarkdownRemark.html }}
          />
        </div>
        <div tw="mb-4 sm:mb-0">
          <div css={iframeContainerStyles}>
            <iframe
              ref={ref}
              title={heading}
              src={videoEmbedUrl}
              css={iframeStyles}
              frameBorder="0"
              border="0"
              scrolling="no"
              mozallowfullscreen="1"
              webkitallowfullscreen="1"
              allowFullScreen="1"
              allow="autoplay; fullscreen"
            />
          </div>
        </div>
      </Inner>
    </Section>
  );
}

export default TextAndVideo;
