import React from 'react';
import tw, { css } from 'twin.macro';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Section, Inner, SectionTitle, contentStyles } from 'components';

const reverseOrderStyles = css`
  @media only screen and (min-width: 769px) {
    grid-auto-flow: column;
    > div:first-of-type {
      grid-column-end: -1;
    }
  }
`;

const gridStyles = tw`flex flex-col-reverse sm:(grid grid-cols-2 grid-rows-1 gap-12)`;

const TextAndImage = ({ heading, bodyTextNode, image, reverseOrder }) => (
  <Section>
    <Inner css={[gridStyles, reverseOrder && reverseOrderStyles]}>
      <div>
        <SectionTitle>{heading}</SectionTitle>
        <div
          tw="mt-4"
          css={contentStyles}
          dangerouslySetInnerHTML={{ __html: bodyTextNode.childMarkdownRemark.html }}
        />
      </div>
      <div tw="mb-4 sm:mb-0">
        <GatsbyImage image={image.gatsbyImageData} placeholder="blurred" alt={heading} />
      </div>
    </Inner>
  </Section>
);

export default TextAndImage;
